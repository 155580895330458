export const menuList = [
  { key: 'menu:dashboard', name: 'Dashboard', icon: 'layers', path: '/' },
  { key: 'menu:leads', name: 'Potennsial mijozlar', icon: 'life-buoy', path: '/leads' },
  { key: 'menu:registration', name: 'Registratsiya', icon: 'file-plus', path: '/appointment' },
  { key: 'menu:patients', name: 'Bemorlar', icon: 'heart', path: '/patients' },
  { key: 'menu:doctors', name: 'Shifokolar', icon: 'user-plus', path: '/doctors' },
  { key: 'menu:employees', name: 'Xodimlar', icon: 'users', path: '/employees' },
  { key: 'menu:rooms', name: 'Xonalar', icon: 'layout', path: '/rooms' },
  { key: 'menu:roles', name: 'Rollar', icon: 'package', path: '/roles' },
  { key: 'menu:permissions', name: 'Ruxsatlar', icon: 'settings', path: '/perm' }
]
